.M-period {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 80px;
    .M-period_list {
        width: 100%;
        li {
            margin-bottom: 10px;
            width: 100%;
            .list {
                width: 100%;
                padding: 14px 16px;
                background-color: #F5F8F6;
                .list_1, .list_2 {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .list_1 {
                    font-size: 20px;
                    font-family: MADE-Tommy-Soft-Medium;
                    color: #111;
                }
                .list_2 {
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: rgba(0,0,0,0.36);
                }
            }
            .first_tips {
                margin-top: 4px;
                width: 100%;
                padding: 15px 12px 10px;
                background-color: #FAFAFA;

                font-size: 14px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #A9A9A9;
            }

            &:nth-child(1) {
                .list {
                    background-color: #FDDD00;
                    .list_1 {
                        color: #333;
                    }
                    .list_2 {
                        color: #CFB500;
                    }
                }
            }
        }
    }
    .M-period_button {
        width: 100%;
        padding: 10px 24px 20px;
        position: fixed;
        left: 0;
        bottom: 0;
        background: #FFFFFF;
    }
}