.P-jointLogonFail {
  width: 100%;
  height: 100%;
  background-color: #fff;

  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .P-jointLogonFail_content {
    width: 100%;
    position: relative;
    z-index: 1;
    .jointFailIcon {
      width: 148px;
      margin: 40% auto 0;
    }
    .P-jointLogonFail_tips {
      width: 100%;
      text-align: center;
      .tips_status {
        padding: 0 70px;
        margin-top: 6px;
        font-size: 16px;
        font-family: MADE-Tommy-Soft-Regular;
        color: #2D2D2D;
      }
      .tips_txt {
        padding: 0 52px;
        margin-top: 6px;
        font-size: 14px;
        font-family: MADE-Tommy-Soft-Regular;
        color: #A9A9A9;
      }
    }
  }
  .P-jointLogonFail_btnArea {
    width: 100%;
    padding: 20px 16px 38px;
    background-color: #fff;
    display: flex;
    justify-content: center;

    position: fixed;
    left: 0;
    bottom: 0;
  }
}