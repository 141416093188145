.P-repaymentOfCard {
    width: 100%;
    padding: 16px 16px 140px;
    .P-repaymentOfCard_btn {
        width: 100%;
        padding: 24px 16px 34px;
        background-color: #fff;
        position: fixed;
        left: 0;
        bottom: 0;
    }
}