.M-button {
    width: 100%;
    .M-button_content {
        width: 100%;
        line-height: normal;
        padding: 18px 0;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #FDDD00;
        border: 2px solid #FDDD00;
        border-radius: 2px;
        color: #2D2D2D;
        p {
            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
                  
            // letter-spacing: 2px;
            // -webkit-text-stroke: 10px transparent;
            // background: #571B00 top left / 100% 100%;
            // -webkit-background-clip: text;
        }

        .btnIcon {
            width: 34px;
            margin-right: 16px;
        }
    }
}