.P-loanDetails {
    width: 100%;
    height: 100%;
    padding: 0 0 160px;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-loanDetails_status {
        width: 100%;
        .statusArea {
            width: 100%;
            padding: 38px 24px 16px;
            background-image: url(../../common/images/status/normal_bg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .statusArea_statusImg {
                width: 72px;
                margin: 0 auto 8px;
            }
            .statusArea_desc {
                text-align: center;
                font-size: 14px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #FFFFFF;
                margin-bottom: 15px;
            }
            .statusArea_tips {
                padding: 16px 12px 11px;
                background-image: url(../../common/images/status/tipsBg.png);
                background-size: 100% 100%;
                background-repeat: no-repeat;

                font-size: 14px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #fff;
                span {
                    color: #E43B3A;
                }
            }
        }
        .failStatus {
            background-image: url(../../common/images/status/error_bg.png);
        }
    }
    .P-loanDetails_info {
        // flex: 1;
        width: 100%;
        padding: 0 24px;
        .info_dataArea {
            margin-bottom: 10px;
            width: 100%;
            padding: 20px 0;
            background-color: #fff;
            border-bottom: 2px dashed #F3F5F7;
            li {
                margin-bottom: 24px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                font-size: 16px;
                .left {
                    text-align: left;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #2D2D2D;
                }
                .right {
                    text-align: right;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #2D2D2D;
                }
                .r_logo {
                    display: flex;
                    justify-content: right;
                    align-items: center;
                    img {
                        width: 20px;
                        margin-right: 3px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .P-loanDetails_button {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .paperBtn {
        width: 100%;
    }
    .buttonArea {
        width: 100%;
        padding: 22px 15px 35px;
        background-color: #fff;
    }

    .P-loanDetails_score {
        width: 100%;
        .score_mask {
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 996;
            animation: mask_show 0.5s linear;
        }
        .score_info {
            width: 327px;
            position: absolute;
            top: 52%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 997;
            animation: modal_centerShow 0.5s linear;
            .score_topIcon {
                width: 122px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .info {
                width: 100%;
                padding: 57px 30px 24px;

                background-color: #fff;
                border-radius: 4px;
                .info_title {
                    text-align: center;
                    font-size: 18px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #FDDD00;
                }
                .info_desc {
                    text-align: center;
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #B3C0BD;
                }
                .star {
                    margin: 26px 0 32px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img {
                        width: 36px;
                    }
                    .rotate_star {
                        animation: stars 1s;
                    }
                }
                .score_buttonArea {
                    width: 100%;
                }
            }
            .closeIcon {
                width: 40px;
                margin: 16px auto 0;
            }
        }
    }
}

@keyframes stars {
    0% {
        transform: scale(0.8);
    }
    60% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}