.M-card {
    width: 100%;
    .M-card_amount {
        margin-bottom: 10px;
        width: 100%;
        padding: 22px 0 28px;
        background-color: #FDDD00;

        text-align: center;
        .title {
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #333333;
        }
        .amount {
            font-size: 32px;
            font-family: MADE-Tommy-Soft-Medium;
            color: #333333;
        }
    }
    .M-card_choseArea {
        width: 100%;
        padding: 18px 20px;
        background-color: #FAFAFA;
    }
    .M-card_typeList {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cardType {
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #A9A9A9;
            p {
                padding-bottom: 20px;
            }
        }
        .choosedCardType {
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Medium;
            color: #333333;
            p {
                padding-bottom: 20px;
                position: relative;
                // -webkit-text-stroke: 10px transparent;
                // background: #21202E top left / 100% 100%;
                // -webkit-background-clip: text;
                &::after {
                    content: "";
                    display: inline-block;
                    width: 40%;
                    height: 3px;
                    background-color: #333333;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .M-card_list {
        width: 100%;
        height: 180px;
        padding: 12px 0 0;
        overflow-y: auto;
        background-color: #FAFAFA;
        li {
            margin-bottom: 24px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .list_info {
                display: flex;
                justify-content: left;
                align-items: center;

                font-size: 14px;
                font-family: MADE-Tommy-Soft-Medium;
                color: #333333;
                img {
                    width: 28px;
                    margin-right: 8px;
                    border-radius: 50%;
                    background-color: #D9D9D9;
                }
                .checkedItem {
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Medium;
                    p {
                        // padding: 0 5px;
                        color: #333333;
                        // -webkit-text-stroke: 10px transparent;
                        // background: #21202E top left / 100% 100%;
                        // -webkit-background-clip: text;
                    }
                }
            }
            .checkedImg, .not_checkedImg {
                width: 24px;
            }
        }
    }
    .M-card_none {
        width: 100%;
        height: 180px;
        padding-top: 10px;
        background-color: #FAFAFA;
        img {
            width: 160px;
            margin: 0 auto;
        }
    }
}