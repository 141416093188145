.M-errorModal_mask {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
    animation: mask_show 0.5s linear;
}
.M-errorModal_info {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: #fff;
    animation: modal_centerShow 0.5s linear;
    .closeIcon {
        width: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1223;
    }
    .infoArea {
        width: 100%;
        min-height: 100px;
        padding: 20px 28px 10px;
        text-align: center;
        .bankError_limit, .bankError_error {
            width: 56px;
            margin: 0 auto 10px;
        }
        .bankError_normal {
            width: 56px;
            margin: 0 auto 10px;
        }
        .infoArea_loanFailedTitle {
            font-size: 18px;
            font-family: MADE-Tommy-Soft-Regular;

            color: #2D2D2D;
            // letter-spacing: 0;
            // -webkit-text-stroke: 14px transparent;
            // background: #FFEABF top left / 100% 100%;
            // -webkit-background-clip: text;
        }
        .infoArea_loanFailedTxt {
            margin-top: 10px;
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #B4B4B4;
            text-align: center;
        }
    }
    .infoArea_btn {
        width: 100%;
        padding: 16px 24px 20px;
        background-color: #F5F8F6;
        margin: 10px auto 0;
    }
    .btnArea {
        margin-top: 10px;
        width: 100%;
        padding: 16px 24px 20px;
        background-color: #F5F8F6;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        .btnArea_btn {
            width: 100%;
        }
    }
}