.P-agreement {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    padding: 20px 16px;
    padding-bottom: 60px;
    background: #f5f5f5;
    p {
        font-size: 14px;
        font-family: MADE-Tommy-Soft-Regular;
        margin: 6px 0;
    }
    .title {
        font-size: 18px;
        font-family: MADE-Tommy-Soft-ExtraBold;
        margin-bottom: 10px;
    }
    .subtitle {
        font-size: 16px;
        font-family: MADE-Tommy-Soft-ExtraBold;
        margin: 6px 0;
    }
    .sub_subtitle {
        font-size: 14px;
        font-weight: MADE-Tommy-Soft-ExtraBold;
    }
    .email {
        text-decoration: underline;
        color: #2f5ba7;
        font-weight: bold;
        font-family: MADE-Tommy-Soft-ExtraBold;
    }
}