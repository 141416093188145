.P-customerService {
    width: 100%;
    height: 100%;
    padding: 0 0 24px;
    padding-bottom: 16px;

    overflow: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-customerService_tips {
        width: 100%;
        background-color: #FDDD00;
        .tips {
            width: 100%;
            padding: 33px 144px 40px 28px;
            border-radius: 2px;

            text-align: left;
            .tips_1 {
                font-size: 18px;
                font-family: MADE-Tommy-Soft-ExtraBold;
                color: #2D2D2D;
            }
            .tips_2 {
                font-size: 14px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #2D2D2D;
            }
        }
    }
    .P-customerService_content {
        flex: 1;
        width: 100%;
        margin-top: 10px;
        position: relative;
        z-index: 1;
        .P-customerService_content_list {
            width: 100%;
            .P-customerService_content_list_li {
                width: 100%;
                padding: 20px 24px 10px;
                margin-bottom: 10px;
                background-color: #fff;

                display: flex;
                justify-content: center;
                align-items: center;
                .list_con_right {
                    width: 100%;
                    text-align: left;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    img {
                        width: 24px;
                        margin-right: 18px;
                    }
                    .list_con_p {
                        width: 100%;
                        font-family: MADE-Tommy-Soft-Regular;
                        font-size: 14px;
                        color: #B3C0BD;
                        .list_con_p_top {
                            font-family: MADE-Tommy-Soft-Regular;
                            font-size: 18px;
                            margin-bottom: 2px;

                            color: #2D2D2D;
                            // letter-spacing: 1px;
                            // -webkit-text-stroke: 9px transparent;
                            // background: #3E1B1B top left / 100% 100%;
                            // -webkit-background-clip: text;
                        }
                    }
                }
                .list_con_left {
                    width: 24px;
                }
            }
        }
    }
}