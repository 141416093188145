.P-bindCard {
    width: 100%;
    height: 100%;
    padding: 0 0 140px;
    background-color: #F5F8F6;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-bindCard_inputArea {
        // flex: 1;
        width: 100%;
        .inputArea {
            width: 100%;
            padding: 24px 18px;
            .inputArea_typeList {
                margin-bottom: 24px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .type {
                    width: 46%;
                    padding: 16px 0;
                    background-color: #fff;
                    border-radius: 1px;

                    text-align: center;
                    font-size: 16px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #B3C0BD;
                    border: 1px solid #fff;
                }
                .choosed_type {
                  background-color: #fff;
                  color: #FDDD00;
                  border: 1px solid #FDDD00;
                }
            }
            .inputArea_inputList {
                width: 100%;
                .inputList {
                  margin-bottom: 24px;
                  .inputList_title {
                    display: inline-block;
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #000;
                    margin-bottom: 10px;
                  }
                  .inputList_input {
                    font-family: MADE-Tommy-Soft-Regular;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 1px;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    .inputList_input_con, .inputList_input_inputchoosedbg {
                      width: 85%;
                      padding: 16px;
                      position: relative;
                      .inputList_input_itemImage, .inputList_input_itemImage_none {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 16px;
                        width: 24px;
                      }
                      .inputList_input_itemImage_none {
                        height: 24px;
                        background-color: #B3C0BD;
                        border-radius: 50%;
                      }
                      input {
                        width: 100%;
                        background: transparent;

                        text-overflow: ellipsis;
                        overflow-x: hidden;
                        white-space: nowrap;
                        
                        font-size: 16px;
                        color: #FDDD00;
                        &::placeholder {
                          width: 100%;
                          color: #B3C0BD;
                        }
                      }
                      input[disabled] {
                        color: #B3C0BD!important; /* 指定禁用状态下的文字颜色 */
                        -webkit-text-fill-color: #B3C0BD!important; /* iOS中禁用状态下文字颜色 */
                        pointer-events: none; /* 禁止输入 */
                        background-color: transparent; /* 去除背景色 */
                        border: none; /* 去除边框 */
                      }
                      .input_click {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        // background-color: #82E318;
                        background: transparent;
                        color: #fff!important; /* 指定禁用状态下的文字颜色 */
                        -webkit-text-fill-color: #fff!important; /* iOS中禁用状态下文字颜色 */
                      }
                      .input_choose_area {
                        padding-left: 30px;
                      }
                    }
                    .inputList_input_selectIcon {
                      width: 24px;
                      height: 24px;
                      position: absolute;
                      right: 16px;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }
            }
            .inputArea_button {
                width: 100%;
                padding: 20px 16px 34px;
                background-color: #fff;
                position: fixed;
                left: 0;
                bottom: 0;
                z-index: 11;
            }
        }
    }
    .cardModal {
      width: 100%;
     padding: 10px 0 60px;
      .cardModal_cardItemList {
        margin-bottom: 10px;
        width: 100%;
        padding: 16px;
        background-color: #F5F8F6;

        display: flex;
        justify-content: center;
        align-items: center;
        .item_info {
          display: flex;
          justify-content: center;
          align-items: center;
          .item_icon {
            width: 24px;
            margin-right: 4px;
          }
          .normalItemColor {
            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #000;
          }
        }
      }
      .chosedItem {
        background-color: #FDDD00;
        .choosedItemColor {
          font-size: 16px;
          font-family: MADE-Tommy-Soft-Regular;
          color: #fff;
        }
      }
    }
}