.M-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    padding: 3px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    .M-header_navLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
            width: 10px;
            margin-right: 2px;
        }
        .media_logo {
            width: 120px;
            padding: 6px 0;
            margin-right: 6px;
        }
        .navLogo_title {
            font-size: 3px;
            color: #173A56;
            font-weight: bold;
        }
        .media_title {
            font-size: 14px;
            color: #173A56;
            font-weight: bold;
        }
    }
    .M-header_navgation {
        display: flex;
        justify-content: right;
        align-items: center;
        li {
            cursor: pointer;
            font-size: 3px;
            color: #333333;
            font-family: Fredoka_Bold;
            padding: 0;
            text-align: center;
            margin: 0 14px;
        }
        .slider {
            font-family: Fredoka_Bold;
            font-size: 3px;
            color: #333333;
            font-weight: bold;
            position: relative;
            p {
                // padding: 0 2px;
                // -webkit-text-stroke: 1.5px transparent;
                // background: #560000 top left / 100% 100%;
                // -webkit-background-clip: text;
            }
        }
    }
    .M-header_mediaIcon {
        width: 100px;
        margin-right: -50px;
    }
    .M-header_mediaNavgation {
        position: absolute;
        right: 0;
        top: 50px;
        background-color: #FFFFFF;
        padding: 10px;
        max-height: 220px;
        max-width: 300px;
        animation: show_nav 0.4s;
        li {
            font-size: 14px;
            padding: 10px;
            border-bottom: 1px solid #080804;
            font-family: PlusJakartaSans-SemiBold;
            &:last-child {
                border: none;
            }
        }
        .slider {
            background-color: #FFE5D2;
            color: #FFFFFF;
        }
    }
    .M-header_mediaNavgation::before {
        content: "";
        display: block;
        border: 16px solid transparent;
        border-bottom: 16px solid #FFFFFF;
        position: absolute;
        top: -25px;
        right: 38px;
    }
}

@keyframes show_nav {
    0% {
        transform: translateX(100%);
    }
    90% {
        transform: translateX(-5%);
    }
    95% {
        transform: translateX(5%);
    }
    100% {
        transform: translateX(0);
    }
}