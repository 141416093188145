.P-bindCardList {
    width: 100%;
    height: 100%;
    padding: 0 0 44px;
    background-color: #F5F8F6;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-bindCardList_list {
        // flex: 1;
        width: 100%;
        padding: 20px 15px 0;
        .list { // div
            width: 100%;
        }
        .list_s { // ul
            width: 100%;
            margin-bottom: 10px;
            .s_info { // li
                width: 100%;
                padding: 20px 16px 12px 20px;
                margin-bottom: 10px;

                background-color: #FFFFFF;
                border-radius: 2px;
                .s_info_txt {
                    width: 100%;
                    .txt {
                        width: 100%;
                        .name {
                            font-size: 18px;
                            font-family: MADE-Tommy-Soft-Bold;
                            color: #222222;

                            display: flex;
                            justify-content: left;
                            align-items: center;
                            .card_logo {
                                width: 20px;
                                border-radius: 50%;
                                margin-right: 6px;
                            }
                        }
                        .type {
                            font-size: 14px;
                            font-family: MADE-Tommy-Soft-Medium;
                            color: rgba(0,0,0,0.5);
                            margin: 6px 0;
                        }
                        .num {
                            font-size: 20px;
                            font-family: MADE-Tommy-Soft-Medium;
                            color: #222222;
                        }
                    }
                }
                .limit_card {
                    margin-top: 10px;
                    width: 100%;
                    padding: 10px 14px;
                    background-color: #FEF6E5;
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #F8AD1E;

                    display: flex;
                    justify-content: left;
                    align-items: center;
                    img {
                        width: 22px;
                        margin-right: 6px;
                    }
                }
                .error_card {
                    margin-top: 10px;
                    width: 100%;
                    padding: 10px 14px;
                    background-color: #FFE3E2;
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #B12524;

                    display: flex;
                    justify-content: left;
                    align-items: center;
                    img {
                        width: 22px;
                        margin-right: 6px;
                    }
                }
            }
            .chosedList {
                background-color: #FDDD00;
                .s_info_txt {
                    .txt {
                        .name {
                            color: #fff;
                        }
                        .type {
                            color: #A9A9A9;
                        }
                        .num {
                            color: #fff;
                        }
                    }
                }
            }
            .s_add {
                width: 100%;
                padding: 16px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                background-color: #fff;
                .card_add {
                    width: 24px;
                    margin-right: 6px;
                }
                p {
                    text-align: center;
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #A9A9A9;
                }
            }
        }
    }
}