.P-code {
    width: 100%;
    height: 100%;
    padding: 16px 16px 44px;
    background-color: #FDDD00;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-code_info {
        // flex: 1;
        width: 100%;
        padding: 20px;
        background: #fff;
        .countdown_tips {
            width: 100%;
            padding: 10px 10px 6px;

            text-align: left;
            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #A9A9A9;
            span {
                color: #A9A9A9;
            }
        }
        .info_countAndCode {
            width: 100%;
            margin: 10px auto;
            .countdown {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    font-size: 20px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #333333;

                    padding: 3px 4px;
                    background-color: #FAFAFA;

                    // padding: 0 6px;
                    // color: #FDF756;
                    // letter-spacing: 6px;
                    // -webkit-text-stroke: 16px transparent;
                    // background: #560000 top left / 100% 100%;
                    // -webkit-background-clip: text;
                    // text-shadow: -6px 6px 0px rgba(0,0,0,0.25);
                }
                img {
                    width: 4px;
                    margin: 0 9px;
                }
            }
        }
        .info_codeInfo {
            width: 100%;
            padding: 22px 18px 20px;
            background: #333;
            border-radius: 2px;
            border: 2px solid #333;
            .codeInfo_repayMethod {
                width: 100%;
                padding-bottom: 20px;
                border-bottom: 2px dashed #fff;
                display: flex;
                justify-content: space-between;
                .method {
                    display: flex;
                    justify-content: left;
                    align-items: center;

                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Medium;
                    color: #fff;
                    img {
                        width: 24px;
                        border-radius: 50%;
                        background-color: #fff;
                        margin-right: 6px;
                    }
                }
                .copy {
                    width: 20px;
                }
            }
            .code {
                padding-top: 15px;
                text-align: center;
                font-size: 22px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #fff;
            }
            .barcodeArea {
                width: 100%;
                margin: 0 auto;
                .barCode {
                    width: 100%;
                }
            }
        }
    }
    .info_repay {
        width: 100%;
        padding: 24px 20px;
        background-color: #E9DBC9;
        li {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #333333;
        }
    }
    .step {
        width: 100%;
        padding: 20px 0;
        .step_guidelines {
            display: flex;
            justify-content: left;
            align-items: center;

            font-size: 16px;
            font-family: MADE-Tommy-Soft-Medium;
            color: #000;
            .code_stepIcon {
                width: 20px;
                margin-right: 6px;
            }
        }
        .step_title {
            display: flex;
            justify-content: left;
            align-items: center;

            margin: 10px 0;
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #CFB500;
            .step_title_index {
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #FDDD00;
                margin-right: 6px;
            }
        }
        .step_info {
            padding-left: 10px;
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #CFB500;
            p {
                margin: 6px 0;
            }
        }
    }
}