html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body { 
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 0 auto; 
    font-weight: normal; 
    text-shadow: none !important;

    background-color: #F6F6F7;
}
#root {
    background-color: #FFFFFF;
}
.App {
    width: 100%;
    height: 100vh;
}