.M-modal {
    width: 100%;
    height: 100%;
    .M-modal_mask {
        width: 100%;
        height: 100%;
        background-color: hsl(0, 0%, 0%);
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        animation: mask_show 0.5s linear;
    }
    .M-modal_content {
        width: 90%;
        padding: 22px 16px;
        background: #FFFFFF;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1010;
        animation: modal_centerShow 0.5s;
        .top {
            width: 100%;
            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #FDDD00;

            display: flex;
            justify-content: center;
            align-items: center;
        }
        .closeIcon {
            width: 24px;
            position: absolute;
            top: 16px;
            right: 16px;
        }
        .content {
            width: 100%;
            min-height: 100px;
            max-height: 320px;
            overflow-y: auto;
        }
        .scrollArea {
            position: relative;
            z-index: 1111;
        }
    }
    .bottom {
        width: 100%;
        padding-bottom: 44px;
        left: 0;
        bottom: 0;
        transform: translate(0, 0);
        z-index: 1010;
        animation: modal_bottomShow 0.8s;
    }
}