/* ios兼容 安全区域 撑开顶部刘海屏和底部黑条 */
.safeArea {
    width: 100%;
}
.App_top {
    width: 100%;
    height: 0;
    /* background-color: #666; */
    /* 兼容 iOS < 11.2 */
    padding-top: 88px;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS >= 11.2 */
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
}
.paddingTop {
    width: 100%;
    height: 0;
    padding-top: 60px!important;
}
.App_bottom {
    width: 100%;
    height: 0;
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
}