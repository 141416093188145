.P-confirmOfLoan {
    width: 100%;
    height: 100%;
    padding: 0 0 160px;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */

    .loan-detail {
        background: #FDDD00;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        padding: 13px 0;
        >div {

            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .load-detail-p {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 13px;
            color: #000000;
            img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }
        }
        .load-detail-second-p {
            font-weight: 400;
            font-size: 18px;
            color: #000000;
            margin-top: 10px;
        }
    }

    .P-title {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        padding-left: 24px;
        padding-bottom: 10px;
    }
    .loan-terms {
        background: #FAFAFA;
        border-radius: 4px 4px 4px 4px;
        padding: 17px 18px;
    }
    .loan-terms-title{
        font-weight: 800;
        font-size: 18px;
        color: #000000;
    }

    .loan-term {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12px;
        color: #2D2D2D;
        margin-top: 18px;
        .loan-term-left {
            //font-weight: 400;
            //font-size: 12px;
            //color: #2D2D2D;
        }
    }

    .payment-plans {
        background: #FAFAFA;
        border-radius: 0px 0px 0px 0px;
        position: relative;
        margin-top: 15px;
        padding: 26px 18px;
        >ul {
            margin-top: 46px;
        }
    }
    .payment-plan-title-txt {
        font-weight: 800;
        font-size: 18px;
        color: #000000;
    }

    .payment-icon {
        position: absolute;
        width: 56px;
        height: 56px;
        top: 6px;
        right: 6px;
    }
    .note {
        background: #FFFAD6;
        border-radius: 0px 0px 0px 0px;
        padding: 8px 9px 21px 15px;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
    }
    .payment-plan-item-wrapper {
        margin-bottom: 10px;
    }
    .payment-plan-item {

      background: #FFFFFF;
      border-radius: 2px 2px 2px 2px;
        width: 100%;
        padding: 8px 14px;
        &.active {
            border: 1px solid #FDDD00;
        }
        >div:nth-child(1) {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            color: #A9A9A9;
        }
        >div:nth-child(2) {
            display: flex;
            justify-content: space-between;
            >span:nth-child(1) {
                font-weight: bold;
                font-size: 18px;
                color: #333333;
            }
            >span:nth-child(2) {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        img {
            width: 40%;
        }
    }

    .P-confirmOfLoan_amount {
        width: 100%;
        margin-bottom: 12px;
        width: 100%;
        padding: 20px 24px;
        background: #F6F6F6;
        .amount_title {
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #BEBEBE;
            margin-bottom: 10px;
        }
        .amount {
            width: 100%;
            font-size: 24px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #2D2D2D;
            // letter-spacing: 6px;
            // -webkit-text-stroke: 18px transparent;
            // background: #560000 top left / 100% 100%;
            // -webkit-background-clip: text;
            // text-shadow: -10px 16px 0px rgba(0,0,0,0.25);

            padding-bottom: 10px;
            border-bottom: 1px solid #2D2D2D;

            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
                width: 48px;
                margin-left: 10px;
            }
        }
        .shelfImg {
            width: 100%;
            margin-top: 10px;
        }
        .amount_countdown {
            margin-top: 12px;
            width: 100%;
            padding: 10px 14px;
            background: #E8EBF6;
            border-radius: 2px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            .countdown_tips {
                width: 70%;
                display: flex;
                justify-content: left;
                align-items: center;

                font-size: 14px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #FDDD00;
                .confirm_countdownIcon {
                    width: 24px;
                    margin-right: 5px;
                }
                span {
                    color: #B12524;
                }
            }
            .countdown_cancel {
                font-size: 14px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #B12524;
            }
        }
    }
    .P-confirmOfLoan_infoArea {
        flex: 1;
        width: 100%;
        padding: 0 24px;
        .title_note {
            width: 100%;
            padding: 10px;
            padding-left: 25%;
            background-image: url(../../common/images/confirmOfLoan/confirm_noteBg.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-bottom: 20px;

            font-size: 12px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #fff;
        }
        .infoArea_bank {
            margin-bottom: 20px;
            width: 100%;
            padding: 14px 12px;
            background-color: #FAFAFA;
            border-radius: 4px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            .bank_info {
                width: 100%;
                .name {
                    font-size: 14px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #333333;
                    margin-bottom: 12px;
                }
                .card {
                    width: 100%;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    .error_tipsIcon {
                        width: 28px;
                        margin-right: 5px;
                    }
                    .card_num {
                        font-size: 20px;
                        font-family: MADE-Tommy-Soft-Regular;
                        color: #333333;
                    }
                }
            }
            .confirm_goBank {
                width: 24px;
                margin-left: 10px;
            }
        }
        .infoArea_repay {
            width: 100%;
            background-color: #fff;
            border-radius: 4px;
            .repay_title {
                margin-bottom: 10px;
                font-size: 16px;
                font-family: MADE-Tommy-Soft-Regular;
                color: #333333;
                .title_time {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
            .repay_info {
                width: 100%;
                padding: 16px 20px;
                background-color: #FAFAFA;
                .repay_info_title {
                    width: 100%;
                    font-size: 16px;
                    font-family: MADE-Tommy-Soft-Regular;
                    color: #333333;

                    padding-bottom: 16px;
                    border-bottom: 2px dashed #F1F1F1;
                }
                li {
                    margin-top: 20px;
                    width: 100%;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .info {
                        width: 100%;
                        font-family: MADE-Tommy-Soft-Regular;
                        .info_amount, .info_date {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            font-size: 16px;
                            color: #949494;
                        }
                        .info_date {
                            font-size: 12px;
                            color: #A9A9A9;
                        }
                    }
                }
            }
        }
        .infoArea_product {
            width: 100%;
            padding: 22px 16px 20px;
            background-color: #FAFAFA;
            border-radius: 4px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #000000;
            .product {
                display: flex;
                justify-content: right;
                align-items: center;
                .productLogo {
                    width: 20px;
                    background-color: #D9D9D9;
                    margin-right: 6px;
                }
            }
        }
    }
    .P-confirmOfLoan_bottom {
        width: 100%;
        background: #FDDD00;

        //padding-bottom: calc(10px + env(safe-area-inset-bottom));
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;
        .bottom_agreement {
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            img {
                width: 20px;
                margin-right: 6px;
            }
            font-size: 14px;
            font-family: MADE-Tommy-Soft-Regular;
            color: #A9A9A9;
            span {
                color: #000;
            }
        }
        .bottom_btnArea {
            width: 100%;
            //margin: 16px auto 0;
        }
    }
    .p-confirmOfLoan_errorBank {
        margin-top: 10px;
        width: 100%;
        padding: 10px 18px;
        .bankName {
            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
            text-align: left;
            margin-bottom: 2px;
        }
        .errorBank_info {
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center;

            font-size: 16px;
            font-family: MADE-Tommy-Soft-Regular;
            .error_tipsIcon {
                width: 24px;
                margin-right: 6px;
            }
        }
    }
    // 此包不用
    .agreementArea {
        color: #666;
        .agreementArea_agreement {
            padding-top: 4px;
            padding-bottom: 100px;
            p {
                font-size: 14px;
                font-family: MADE-Tommy-Soft-Regular;
                margin: 6px 0;
            }
            .title {
                font-size: 18px;
                font-family: MADE-Tommy-Soft-ExtraBold;
                margin-bottom: 10px;
            }
            .subtitle {
                font-size: 16px;
                font-family: MADE-Tommy-Soft-ExtraBold;
                margin: 6px 0;
            }
            .sub_subtitle {
                font-size: 14px;
                font-weight: MADE-Tommy-Soft-ExtraBold;
            }
            .email {
                text-decoration: underline;
                color: #2f5ba7;
                font-weight: bold;
                font-family: MADE-Tommy-Soft-ExtraBold;
            }
        }
        .agreementArea_btn {
            width: 100%;
            padding: 16px 16px 22px;
            background-color: #fff;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 1012;
        }
    }
}