.P-repayment {
    width: 100%;
    height: 100%;
    padding: 0 0 140px;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-repayment_amount {
        width: 100%;
        padding: 16px 24px;
        background-color: #09C8AF;
    }
    .P-repayment_planInfo {
        flex: 1;
        position: relative;
        width: 100%;
        padding: 80px 0 120px;
        .repay_info {
            margin-bottom: 30px;
            width: 100%;
            li {
                width: 100%;
                .repayInfo_area {        
                    width: calc(100% - 120px);
                    margin: 0 auto 30px;
                    padding: 20px 34px 20px 16px;
    
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .index {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        align-items: baseline;
                        .index_num {
                            font-size: 60px;
                            font-family: Fredoka_Bold;
    
                            padding: 0 6px;
                            color: #FDF756;
                            letter-spacing: 0px;
                            -webkit-text-stroke: 12px transparent;
                            background: #560000 top left / 100% 100%;
                            -webkit-background-clip: text;
                        }
                        .index_th {
                            margin-left: -8px;
                            font-size: 32px;
                            font-family: Fredoka_Bold;
    
                            padding: 0 6px;
                            color: #FDF756;
                            letter-spacing: 0px;
                            -webkit-text-stroke: 12px transparent;
                            background: #560000 top left / 100% 100%;
                            -webkit-background-clip: text;
                        }
                    }
                    .info {
                        width: 80%;
                        font-size: 32px;
                        font-family: Fredoka_Regular;
                        color: #943800;
    
                        display: flex;
                        justify-content: space-between;
                        .info_amount {
                            text-align: left;
                        }
                        .info_date {
                            text-align: right;
                        }
                    }
                }
                .repayInfo_tips {
                    position: relative;
                    z-index: 11;
                    margin: -60px 0 -30px;
                    width: 100%;

                    padding: 46px 80px 90px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    .tips {
                        font-size: 32px;
                        font-family: Fredoka_Bold;

                        padding: 0 5px;
                        color: #fff;
                        letter-spacing: 2px;
                        -webkit-text-stroke: 10px transparent;
                        background: #571B00 top left / 100% 100%;
                        -webkit-background-clip: text;

                        transform: rotate(-3deg);
                    }
                    .tipsArrow {
                        width: 98px;
                    }
                }
            }
        }
    }
    .P-repayment_delayInfo {
        flex: 1;
        width: 100%;
    }
    .delayInfo {
        margin-bottom: 10px;
        width: 100%;
        background: rgba(255,255,255,0.31);
        padding: 20px;
        border-radius: 2px;
        li {
            margin-bottom: 24px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 16px;
            color: #FFFFFF;
            .info_left {
                text-align: left;
                font-family: MADE-Tommy-Soft-Regular;          
            }
            .info_right {
                text-align: right;
                font-family: MADE-Tommy-Soft-Regular;
            }
            .r_logo {
                display: flex;
                justify-content: right;
                align-items: center;
                img {
                    width: 20px;
                    margin-right: 3px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .detailsInfo {
        background-color: #FFFFFF;
        li {
            color: #2D2D2D;
            &:nth-child(3) {
                padding-bottom: 24px;
                border-bottom: 2px dashed #F3F5F7;
            }
        }
    }
    .P-repayment_btnArea {
        width: 100%;
        padding: 22px 15px 34px;
        background-color: #fff;
        position: fixed;
        left: 0;
        bottom: 0;
    }
}